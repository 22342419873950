import {createNgModuleRef, EnvironmentInjector, Injector, NgModuleRef, Type, ViewContainerRef} from '@angular/core';

export async function createModule<T>(module: Type<T>, parentInjector: Injector) {
    return createNgModuleRef(module, parentInjector);
}

export function createComponent<T>(
    component: Type<T>,
    viewContainer: ViewContainerRef,
    injector?: Injector,
    ngModuleRef?: NgModuleRef<any>,
    environmentInjector?: EnvironmentInjector,
    index = 0
) {
    return viewContainer.createComponent(component, {index, injector, ngModuleRef});
    return viewContainer.createComponent(component, {index, injector, ngModuleRef, environmentInjector});
}

export async function createComponentByLoadingModuleAndComponent<T, M>(
    viewContainer: ViewContainerRef,
    loadComponent: () => Promise<Type<T>>,
    loadModule: () => Promise<Type<M>>,
    injector: Injector
) {
    const module = await loadModule();
    const moduleRef = await createModule(module, injector);
    const component = await loadComponent();
    return createComponent(component, viewContainer, injector, moduleRef);
}

export async function createComponentFromOptions<T, M>({viewContainer, component, loadComponent, loadModule, injector}: {
    viewContainer: ViewContainerRef;
    component?: Type<T>;
    loadComponent?: () => Promise<Type<T>>;
    loadModule?: () => Promise<Type<M>>;
    injector: Injector;
}) {
    const moduleRef: NgModuleRef<any> = loadModule && await createModule(await loadModule(), injector);
    return createComponent(component || await loadComponent(), viewContainer, injector, moduleRef);
}
